.termCon {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}

.titleCon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleText {
    font-weight: 700;
    font-size : 2.5vw;
    text-align: center;
    margin-bottom : 60px;
}

.semiTitleCon {
    width: 100%;
    padding-left : 20%;
    padding-right : 20%;
    box-sizing: border-box;
    margin-bottom : 20px;
}

.semiTitleText {
    font-weight: 600;
    font-size : 1vw;
    margin-top : 0px;
    margin-bottom : 10px;
}

.contentCon {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-items: flex-start;
    margin-top : 0px;
    margin-bottom : 0px;
}

.contentSequenceNum {
    font-weight: 500;
    font-size : 0.9375vw;
    margin-right : 20px;
    margin-top : 5px;
    margin-bottom : 5px;
}

.contentBoldText {
    font-weight: 700;
    font-size : 0.9375vw;
    margin-top : 5px;
    margin-bottom : 5px;
}

.contentText {
    font-weight: 500;
    font-size : 0.9375vw;
    margin-top : 5px;
    margin-bottom : 5px;
}

.dateCon {
    width : 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom : 50px;
}

.dateText {
    font-weight: 500;
    font-size : 0.9375vw;
    margin-top : 5px;
    margin-bottom : 5px;
    text-align: center;
}