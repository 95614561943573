.screen {
    display : flex;
    box-sizing: border-box;
    flex-direction: column;

    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}   

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top : 2.8448vh;
    padding-bottom : 2.0690vh;
    padding-left : 7.5vw;
    padding-right : 7.5vw;
    width : 100vw;
    z-index : 1000;
    box-sizing : border-box;
    top : 0;
    left : 0;
    position : fixed;
    background-color: white;

    @media screen and (min-width:500px) and (max-width : 1440px) {
        padding-top : 24px;
        padding-bottom : 34px;
        padding-left : 6.1111vw;
        padding-right : 6.1111vw;
    }

    @media screen and (min-width : 1441px) {
        padding-top : 24px;
        padding-bottom : 34px;
        padding-left : 9.3750vw;
        padding-right : 9.3750vw;
    }
}

.footerCon02 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left : 7.5vw;
    padding-right : 7.5vw;
    z-index : 999;
    box-sizing : border-box;
    bottom : 0;
    left : 0;
    position : fixed;
    width : 100vw;
    height : 10vh;
    background-color: #8B4DFF;
}

.footerTxt {
    padding : 0;
    margin : 0;
    font-size : 5.0000vw;
    font-family: 'Pretendard';
    font-weight: 700;
    letter-spacing: -1%;
    line-height : 6.5000vw;
    color : white;
    white-space: nowrap;
    text-shadow: none;
}

.headerLogo {
    width: 38.0406vw;
    cursor: pointer;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;

    @media screen and (min-width:500px)  {
        width : 286.27px;
    }
}

.menu {
    width: 8.4375vw;
    height : 8.4375vw;
    cursor: pointer;
}

.headerBtn {
    margin : 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 60px;
    width: 260px;
    height: 68px;
    border-radius: 36px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    background-color: #8B4DFF;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}

.headerBtnTxt {
    padding : 0;
    margin : 0;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    /* identical to box height */
    letter-spacing: -0.03em;

    /* Neutrals/White */
    color: #FFFFFF;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}

.component {
    width: 100vw;
    display : flex;
    flex-direction: column;
    box-sizing: border-box;    
}

.titleTxt01 {
    padding : 0;
    margin : 0;
    padding-left : 7.5vw;
    font-size : 6.8750vw;
    font-family: 'Pretendard';
    font-weight: 700;
    letter-spacing: -3%;
    line-height : 9.6250vw;
    color : black;
    white-space: nowrap;
    text-shadow: none;
}

.contentTxt {
    padding: 0;
    margin: 0;
    padding-left : 7.5vw;
    font-size : 4.3750vw;
    font-family: 'Pretendard';
    font-weight: 400;
    letter-spacing: -3%;
    line-height : 6.1250vw;
    color : #62686D;
    white-space: nowrap;
    text-shadow: none;
}

.grdientDiv {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #F9F5FF 70%);
    width: 100vw;
    padding-top : 40px;
    padding-bottom : 10px;
}

.grdientTxt01 {
    padding-left : 7.5vw;
    font-family: 'Pretendard';
    font-size: 6.2500vw;    
    line-height: 7.5000vw;
    letter-spacing: -0.01em;
    font-weight: 800;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-shadow: none;
    color : #8B4DFF;
}

.grdientTxt02 {
    color: #9F6BFF;
    padding-left : 7.5vw;
    margin : 0;
    font-family: 'Pretendard';
    font-size: 5.0000vw;
    line-height: 6.0000vw;
    font-weight: 700;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-shadow: none;    
}

.titleTxt02 {
    padding : 0;
    margin : 0;
    padding-left : 7.5vw;
    font-size : 6.8750vw;
    font-family: 'Pretendard';
    font-weight: 700;
    letter-spacing: -3%;
    line-height : 100%;
    color : black;
    white-space: nowrap;
    text-shadow: none;
}

.componentImg {
    width : 100vw;
}

.componentIntro {    
    width : 100vw;
    background-color: #F9F5FF;
    padding-left : 7.5vw;
    padding-right : 7.5vw;
    padding-top : 6.8966vh;
    padding-bottom : 6.8966vh;
    margin : 0;
    box-sizing : border-box;
}

.subIntroCon {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.componentIntroTitleTxt {
    font-family: 'Pretendard';
    font-weight: 700;
    font-size: 6.8750vw;
    line-height: 9.6250vw;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : black;
    margin : 0;
    padding : 0;
}

.introTxt {
    font-family: "Pretendard";
    font-size: 5.6250vw;
    line-height: 7.8750vw;
    font-weight: 500;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #454A50;
    text-shadow: none;
    margin : 0;
    padding : 0;
}

.introContentTxt {
    font-family: "Pretendard";
    font-size: 6.8750vw;
    line-height: 9.6250vw;    
    font-weight: 700;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #1B1D1F;
    text-shadow: none;
    margin : 0;
    padding : 0;
}

.introSemiTxt {
    font-family: "Pretendard";
    font-size: 4.6875vw;
    line-height: 6.5625vw;
    font-weight: 400;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #62686D;
    text-shadow: none;
    margin : 0;
    padding : 0;
}

.introSemiTxt02 {
    font-family: "Pretendard";
    font-size: 5.6250vw;
    line-height: 7.8750vw;
    font-weight: 800;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #1B1D1F;
    text-shadow: none;
    margin : 0;
    padding : 0;
    text-align: center;
}

.component06Btn {
    width : 100%;
    margin-top : 0;
    margin-bottom : 0;
    padding-top : 4.3750vw;
    padding-bottom : 4.3750vw;
    border-radius: 36px;
    border : solid 0.8px #9F6BFF;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Pretendard';
    font-weight: 600;    
    font-size: 5.0000vw;
    line-height: 6.0000vw;
    letter-spacing: -0.03em;
    text-align: center;
    color : #9F6BFF;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-shadow : none;
    box-sizing : border-box;
    cursor: pointer;
}

.menuScreen {
    padding-left : 7.5vw;
    padding-right: 7.5vw;
}

.menuButton {
    border-bottom : solid 0.6px #F4F4F5;
    padding-left : 0;
    padding-right: 0;
    padding-top : 6.25vw;
    padding-bottom : 6.25vw;
    margin : 0;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.menuTxt {
    padding : 0;
    margin : 0;
    font-family: "Pretendard";
    font-size: 4.6875vw;
    line-height: 5.6250vw;
    font-weight: 500;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-shadow : none;
}

.menuRightIcon {
    width : 8.4375vw;
    height : 8.4375vw;
}



/* 웹 */
.webScreen {
    width : 100vw;
    box-sizing: border-box;

    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;

    min-width: 800;
}

.component01Header {
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    @media screen and (min-width:500px) and (max-width : 1440px) {
        padding-left : 6.1111vw;
        padding-right : 6.1111vw;
    }

    @media screen and (min-width : 1441px) {
        padding-left : 9.3750vw;
        padding-right : 9.3750vw;
    }
}

.component01RowCon {
    display : flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.component01Logo {
    width : 66px;
}

.component01Btn {
    padding-top : 22px;
    padding-bottom : 22px;
    padding-left : 20px;
    padding-right: 20px;
}

.component01Txt {
    padding : 0;
    margin : 0;
    font-family: 'Pretendard';
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-shadow: none;
    color : #454A50;
}

.componentCon {
    display : flex;
    margin : 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (min-width:500px) and (max-width : 1440px) {
        padding-left : 6.1111vw;
    }

    @media screen and (min-width : 1441px) {
        padding-left : 9.3750vw;
    }
}

.webTitleTxt {
    padding : 0;
    margin : 0;
    font-family: 'Pretendard';
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-shadow: none;
    color : #1B1D1F;
}

.webSubTitleTxt {
    padding : 0;
    margin : 0;
    font-family: 'Pretendard';
    font-size: 24px;
    font-weight: 400;
    line-height: 33.6px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-shadow: none;
    color : #62686D;
}

.webColorSubTitleTxt {
    padding : 0;
    margin : 0;
    font-family: 'Pretendard';
    font-size: 26px;
    font-weight: 700;
    line-height: 31.2px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-shadow : none;
    color : #9F6BFF;
}

.webColorTitleTxt {
    font-family: Pretendard;
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-shadow : none;
    color : #8B4DFF;
}

.image01 {
    width : 869px;
    height : 880px;
    padding : 0;
    @media screen and (min-width:500px) and (max-width : 1440px) {
        margin-right : 0;
    }

    @media screen and (min-width : 1441px) {
        margin-right : 4.7917vw;
    }
}

.componentImage {
    width : 791px;
    height : 880px;
    padding : 0;
    @media screen and (min-width:500px) and (max-width : 1440px) {
        margin-right : 6.1111vw;
    }

    @media screen and (min-width : 1441px) {
        margin-right : 9.3750vw;
    }
}

.gradientCon {
    background: linear-gradient(to top, white, 88%, #F9F5FF)
}

.com6Con {
    width: 100%;
    padding-top : 90px;
    padding-bottom : 50px;
    background-color: #F9F5FF;
    @media screen and (min-width:500px) and (max-width : 1440px) {
        padding-left : 6.1111vw;
    }

    @media screen and (min-width : 1441px) {
        padding-left : 9.3750vw;
    }
}

.com6ColorCon {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #F9F5FF;
    padding-top : 98px;
    padding-bottom : 84px;

    @media screen and (min-width:500px) and (max-width : 1440px) {
        padding-left : 6.1111vw;
        padding-right : 6.1111vw;
    }

    @media screen and (min-width : 1441px) {
        padding-left : 9.3750vw;
        padding-right : 9.3750vw;
    }
}

.com6IconRowCon {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-evenly;
    align-items: center;
    margin : 0;
    padding : 0;
}

.com6IconCon {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    margin : 0;
    padding : 0;
}

.com6Icon {
    width : 124px;
    height : 124px;
    margin : 0;
    padding : 0;
}

.com6infoTxt {
    margin : 0;
    padding : 0;
    font-family: 'Pretendard';
    font-size: 30px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-shadow: none;
    color : #454A50;
}

.com6BtnRowCon {
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left : 10px;
    padding-right : 10px;
    padding-top : 30px;
    padding-bottom : 30px;
    box-sizing: border-box;
    margin : 0;
}

.com6Btn {    
    border-radius: 70px;
    padding-top : 22px;
    padding-bottom : 22px;
    padding-left : 95px;
    padding-right: 95px;

    border : solid 1px #9F6BFF;
    background-color: white;
    margin : 0;
}

.com6BtnTxt {
    margin : 0;
    padding : 0;
    font-family: "Pretendard";
    font-size: 26px;
    font-weight: 600;
    line-height: 31.03px;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #9F6BFF;
}

.com6InfoTxt {
    align-self: center;
    padding : 0;
    margin : 0;
    font-family: "Pretendard";
    font-size: 22px;
    font-weight: 400;
    line-height: 30.8px;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-shadow: none;
    color : #62686D;
}

.com7ColorCon {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #FFFFFF;
    padding-top : 90px;
    padding-bottom : 120px;

    @media screen and (min-width:500px) and (max-width : 1440px) {
        padding-left : 6.1111vw;
        padding-right : 6.1111vw;
    }

    @media screen and (min-width : 1441px) {
        padding-left : 9.3750vw;
        padding-right : 9.3750vw;
    }
}

.footerCon01 {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #FFFFFF;
    padding-top : 79px;
    padding-bottom : 68px;

    @media screen and (min-width:500px) and (max-width : 1440px) {
        padding-left : 6.1111vw;
        padding-right : 6.1111vw;
    }

    @media screen and (min-width : 1441px) {
        padding-left : 9.3750vw;
        padding-right : 9.3750vw;
    }
}

.footerRowCon01 {
    width : 100%;
    padding : 0;
    margin : 0;
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.footerRowCon02 {
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footerRowCon03 {
    display : flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.footerRowCon04 {
    width: 100%;
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footerRowCon05 {
    display : flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.footerIcon {
    width: 44px;
    height : 44px;    
}

.footerIconCon {
    padding : 18px;
    margin : 0;
}

.footerTxt01 {
    padding : 0;
    margin : 0;
    font-family: "Pretendard";
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #797A7C;
}

.footerTxt02 {
    padding : 0;
    margin : 0;
    font-family: "Pretendard";
    font-size: 26px;
    font-weight: 700;
    line-height: 31.2px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #353535;
}

.footerTxt03 {
    padding : 0;
    margin : 0;
    font-family: "Pretendard";

    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;    
    color : #62686D;
}

.footerTxt04 {
    padding : 0;
    margin : 0;
    font-family: "Pretendard";
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;      
    color : #9FA5AD;
}

.footerTxt05 {
    padding : 0;
    margin : 0;
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;     
    color : #BDC1C5;
}
