
.main {
    background-color: #9F6BFF;
    padding-top : 12.5vw;
    padding-bottom : 0;    
}

.main02 {
    background-color: #9F6BFF;
    padding-top : 5vw;
    padding-bottom : 0;    
    margin-bottom : 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.content {
    width: 100%;
    padding-bottom : 0px;
}

.title {
    margin-left : 7.5vw;
    height : 30vw;
}

.title01 {
    margin-left : 7.5vw;
    height : 5.625vw;
}

.title02 {
    margin-left : 7.5vw;
    height : 16vw;
}

.main06 {
    padding-top : 12.5vw;
    background: linear-gradient(to bottom, #FFFFFF 50%, #EDE2FF 50%);
}

.content06 {
    width: 73.1250vw;
    height : 79.8750vw;
    margin-right : 20vw;
}

.button05 {
    position: absolute;
    left: 50%;
    width: 48.1250vw;
    transform: translateX(-50%);
}

.main07 {
    background: linear-gradient(to bottom, #FFFFFF 80%, #F9F4FF 20%);
}

.download {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 27.5;
    padding-right : 27.5
}

.downloadButton {
    width : 40.6250vw;
}

.homeFooterCon {
    width: 100%;
    height : auto;
    padding-top : 9.3750vw;
    padding-bottom : 15.6250vw;
    padding-left : 7.5000vw;
    box-sizing: border-box;
}

.footerBtnCon {
    display: flex;
    flex-direction: row;
    margin-top : 0px;
    margin-bottom : 5vw;
}

.footerBtn {
    width : 6.8750vw;
    height : auto;
    margin-right : 20px;
}

.footerBtn01 {
    margin-top : 0px;
    margin-bottom : 5.0000vw;
    color : #797A7C;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    text-decoration: none;
}

.footerBtn02 {
    margin-top : 0px;
    margin-bottom : 0px;
    color : #797A7C;
    font-weight: 600;
    font-size : 3.7500vw;
    text-decoration: none;
}

.footerBtnCon {
    display: flex;
    flex-direction: row;
}

.footerBtn04 {
    margin-top : 0px;
    margin-right : 3.1250vw;
    margin-bottom : 6.2500vw;
    color : #726E6E;
    font-weight: 700;
    display: flex;
    flex-direction: row;
}

.footerText01 {
    font-weight: 600;
    color : #393939;
    font-size: 3.7500vw;
    margin-top : 0px;
    margin-bottom : 16px;
    line-height: 120%;
}

.footerTextCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    margin-top: 0px;
    margin-bottom : 1.8750vw;
}

.footerTextCon1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-items: center;
    margin-top: 0px;
    margin-bottom : 1.8750vw;
}


.footerVertDivider {
    width : 0.1563vw;
    height : 2.5000vw;
    background-color: #726E6E;
    margin-left : 2.5000vw;
    margin-right : 2.5000vw;
}

.footerTitleTxt {
    color : #726E6E;
    font-weight: 700;
    font-size : 3.1250vw;
    line-height : 100%;
    margin-right : 1.5625vw;
    margin-top: 0px;
    margin-bottom : 0px;
    white-space: nowrap;
}

.footerContentTxt {
    color : #726E6E;
    font-weight: 500;
    font-size : 3.1250vw;
    line-height : 100%;
    margin-top: 0px;
    margin-bottom : 0px;
    line-height: 120%;
}

.footerClcTxt{
    color : #AAAAAA;
    margin-top : 6.2500vw;
    margin-bottom : 0px;
    font-weight: 500;
    font-size : 2.5vw;
}

.faq {
    padding-top : 12.5vw;
    padding-bottom : 12.5vw;
}

.faqCon {
    padding-left : 7.5vw;
    padding-right : 7.5vw;
    padding-top : 4.3750vw;
    padding-bottom : 4.3750vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #FFFFFF;
}

.faqCon01 {
    padding-left : 7.5vw;
    padding-right : 7.5vw;
    padding-top : 4.3750vw;
    padding-bottom : 4.3750vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #FAFAFB;
}

.faqCon02 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding : 0 0 0 0;
}

.faqIcon {
    width : 6.8750vw;
    height : 6.8750vw;
    margin-right : 1.8750vw;
}

.faqTitle {
    margin-left: 7.5vw;
    margin-top : 0px;
    margin-bottom : 0px;
    font-size : 6.25vw;
    font-weight : 700;
    color : #1B1D1F;
}

.faqTitle01 {
    font-size : 5vw;
    font-weight: 400;
    line-height: 7vw;
    letter-spacing: -1%;
    color : #454A50;
    margin : 0 0 0 0;
}

.faqTitle02 {
    font-size : 5vw;
    font-weight: 600;
    line-height: 7vw;
    letter-spacing: -1%;
    color : #1B1D1F;
    margin : 0 0 0 0;
}

.faqContent {
    font-size : 4.3750vw;
    font-weight: 400;
    letter-spacing: -2%;
    line-height : 7vw;
    color : #62686D;
    margin : 0 0 0 0;
    margin-top: 2.5vw;
}

.footerTextBtn {
    margin : 0 0 0 0;
    color : #9FA5AD;
    font-weight: 600;
    font-size: 3.1250vw;
    letter-spacing: -2%;
}

.footer {
    padding-top : 3.7500vw;
    padding-bottom : 3.7500vw;
    padding-left : 6.25vw;
    padding-right : 6.25vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    left : 0px;
    bottom : 0px;
    position : fixed;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 1000;
    box-sizing: border-box;
}

.footerLogo {
    width : 24.3750vw;
}

.downloadFooterBtn {
    width : 22.5625vw;
}


.contentTitle01 {
    color : #9F6BFF;
    font-size: 5vw;
    line-height: 6vw;
    font-weight : 700;
    letter-spacing: -3%;
    margin-top : 0px;
    margin-bottom : 1.8750vw;
    padding-left : 7.5vw;
    padding-right : 7.5vw;
}

.contentTitle02 {
    color : #1B1D1F;
    font-size: 6.8750vw;
    line-height: 9.6250vw;
    font-weight : 700;
    margin-top : 0px;
    letter-spacing: -3%;
    margin-bottom : 5vw;
    padding-left : 7.5vw;
    padding-right : 7.5vw;
}

.contentTitle03 {
    color : #62686D;
    font-size: 4.3750vw;
    line-height: 6.1250vw;
    font-weight : 400;
    margin-top : 0px;
    letter-spacing: -3%;
    margin-bottom : 0px;
    padding-left : 7.5vw;
    padding-right : 7.5vw;
}

.contentTitle04 {
    color : #1B1D1F;
    font-size: 5vw;
    line-height: 7vw;
    font-weight : 500;
    margin-top : 0px;
    letter-spacing: -3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
}

.contentTitle05 {
    color : #1B1D1F;
    font-size: 6.25vw;
    line-height: 8.75vw;
    font-weight : 900;
    margin-top : 0px;
    letter-spacing: -3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
}

.titleTxt {
    margin-left : 7.5vw;
    margin-bottom : 6.25vw;
    font-family: "NanumSquareNeo";
    font-weight: 900;
    color : #FFFFFF;
    font-size: 7.5000vw;
    line-height: 140%;
    text-shadow: 0.0000vw 1px 2px rgba(0, 0, 0, 0.17);
}

.titleDownBtn {
    max-width: 39.6875vw;
    box-sizing: border-box;
    margin-left : 7.5vw;
    padding-left : 4.6875vw;
    padding-right : 4.6875vw;
    padding-top : 1vw;
    padding-bottom : 1vw;
    border-radius: 36px;
    background-color: #FFFFFF;
    font-family: "NanumSquareNeo";
    font-weight: 800;
    font-size : 3.7500vw;
    color : #000000;
    display: flex;

    align-items: center;
    justify-content: center;
    line-height: 0%;
}


.footerCon {
    display : flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-right : 7.5000vw;
}

.footerText02 {
    font-size : 3.4375vw;
    line-height : 200%;
    color : #62686D;
    font-weight: 600;
    cursor: pointer;
}

.footerTxtCon {
    display : flex;
    flex-direction: row;
    justify-content: center;
}

.titleLogoCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.titleLogoTxt {
    margin-right : 7.5vw;
    font-weight : 600;
    font-size: 3.4375vw;
    line-height: 120%;
    letter-spacing: -2%;
    color : white;
    margin-bottom : 30px;
    cursor : pointer;
}