.App {
  height : 100vh;
}

@media (hover: hover) and (pointer: fine) {
  .outer {
    height: 100vh;

  }
}

@media (hover: none) and (pointer: coarse) {
  .outer {
    height: 100vh;
  }
}

/* 화면에서 스크롤바 안보이게 */
.outer::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}



