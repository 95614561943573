@keyframes indicator-ani {
    from { transform : translate(0, 0) }
    to { transform : translate(calc(42.5000vw / 3), 0) }
}

@keyframes indicator-ani-rev {
    from { transform : translate(calc(42.5000vw / 3), 0)  }
    to { transform : translate(0, 0) }
}

@keyframes indicator-ani2 {
    from { transform : translate(calc(42.5000vw / 3), 0) }
    to { transform : translate(calc(42.5000vw / 3 * 2), 0) }
}

@keyframes indicator-ani2-rev {
    from { transform : translate(calc(42.5000vw / 3 * 2), 0) }
    to { transform : translate(calc(42.5000vw / 3), 0) }
}

.indicator {    
    width : calc(42.5000vw / 3);
    height : 1.25vw;
    background-color : #9F6BFF;
    border-radius : 20px;
}

.indicator.ab {
    animation: indicator-ani 0.2s linear;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.indicator.ba {
    animation: indicator-ani-rev 0.2s linear;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.indicator.bc {
    animation: indicator-ani2 0.2s linear;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.indicator.cb {
    animation: indicator-ani2-rev 0.2s linear;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

textarea:focus, input:focus{
    outline: none;
}
*:focus {
    outline: none;
}

.customInput {
    width: 100%;
    border : 0.8px solid #E8E8E9;    
    border-radius: 8px;
    padding : 14px;
    box-sizing: border-box;
    margin-top : 2.1875vh;
    margin-bottom : 4.6875vh;

    font-size: 4.375vw;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -2%;
    color : #454A50;

    &:focus {
        border : 0.8px solid #454A50;    
        border-radius: 8px;
        max-lines: 5;
    }
    &::placeholder {
        font-size: 4.375vw;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -2%;
        color : #BDC1C5;        
    }
    &:user-invalid {
        border : 0.8px solid #FF5C5C;
        border-radius: 8px;
    }
    &:invalid {
        border : 0.8px solid #FF5C5C;
        border-radius: 8px;
    }
}

.customInput.a {
    margin-top : 0.9375vh;
    margin-bottom : 0;
    width : 100%;    
}

.customInput.error {
    border : 0.8px solid #FF5C5C;
    border-radius: 8px;

    &:focus {
        border : 0.8px solid #454A50;    
        border-radius: 8px;
    }
}

.customTextArea {
    width: 100%;
    border : 0.8px solid #E8E8E9;    
    border-radius: 8px;
    padding : 14px;
    box-sizing: border-box;
    margin-top : 2.1875vh;
    margin-bottom : 0;

    font-size: 4.375vw;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -2%;
    color : #454A50;

    &:focus {
        border : 0.8px solid #454A50;    
        border-radius: 8px;
        max-lines: 5;
    }
    &::placeholder {
        font-size: 4.375vw;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -2%;
        color : #BDC1C5;        
    }
    &:user-invalid {
        border : 0.8px solid #FF5C5C;
        border-radius: 8px;
    }
    &:invalid {
        border : 0.8px solid #FF5C5C;
        border-radius: 8px;
    }
}

.customTextArea.error {
    border : 0.8px solid #FF5C5C;
    border-radius: 8px;

    &:focus {
        border : 0.8px solid #454A50;    
        border-radius: 8px;
    }
}

.radio {
   
    /* -webkit-appearance: none; */

    margin-right : 3vw;
    margin-left : 0;
    margin-top: 3px;
    margin-bottom : 0;
    box-sizing: border-box;    
    align-self: center;
    width : 5.2094vw;
    height : 5.2094vw;
    position: relative;
    padding : 0;
    border : 0.8px solid #E8E8E9;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    
    &:checked {        
        /* border : 0.8px solid #9F6BFF; */
    }

    &:checked::after {
        /* background-color : #9F6BFF; */
        border-radius: 100%;
        content : "ㅤ";
        position: relative;        
        width: 2.8125vw;
        height: 2.8125vw;
        margin : auto;
        padding : auto;
        position: absolute; 
        left: 0; 
        right: 0;
        top : 0;
        bottom : 0;

    }

}

.radio.last {
    display : flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right : 3vw;
    margin-left : 0;
    margin-top: 3px;
    margin-bottom : 0;
    align-self: flex-start;
}

.checkBox {
    /* appearance : none; */
    width : 5.2094vw;
    height : 5.2094vw;
    border : 0.8px solid #E8E8E9;    
    border-radius: 8px;
    position: relative;
    display: inline-block;
    margin : auto;
    padding : auto;
    margin-right : 3.1250vw;
    margin-top : 0;
    margin-left : 0;
    margin-bottom : 0;
    box-sizing: border-box;

    &:checked {
        content : url("../../../assets/icon/common/check_white.svg");  
        margin : auto;
        margin-right : 3.1250vw;
        padding : auto;
        border : 0.8px solid #8B4DFF;   
        border-radius: 8px;
        background-color : #9F6BFF;
        box-sizing: border-box;
        padding-top : 0.8656vw;
        padding-bottom : 0.8656vw;
        padding-left : 0.8969vw;
        padding-right : 0.8969vw;
        /* -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; */
    }
}

.checkBox.error {
    border : 0.8px solid #FF5C5C;    
    &:checked:before {
        border : 0.8px solid #FF5C5C;    
    }
}

.customButton {
    flex : 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top : 4.2188vw;
    padding-bottom : 4vw;
    margin : auto;
    box-sizing: border-box;
    border : 0.8px solid #E8E8E9;
    border-radius: 8px;
    text-align: center;
    vertical-align: middle;
    font-size: 4.3750vw;
    line-height: 120%;
    letter-spacing: -2%;
    color : #62686D;
    font-weight: 500;
    vertical-align: bottom;
}

.customButton.selected {
    border : 0.8px solid #9F6BFF;
    border-radius: 8px;
    font-weight: 600;
    line-height: 120%;
    color : #9F6BFF;
}

.noneCon {
    margin : 0px;
    padding : 0px;
    display : flex;
    
}

.angleRight {
    width : 4.0625vw;
    height : 4.0625vw;
    margin-top : 0;
    margin-bottom : 0;
    margin-right : 0;
    margin-left : 3.1250vw;
    cursor : pointer;
}

.radioCon {
    width : 100%;
    display : flex;
    flex-direction : row;
    align-items : flex-start;
    justify-content : flex-start;
    margin-top : 0;
    margin-bottom : 4.3750vw;
    margin-left : 0;
    margin-right : 0;
    box-sizing : border-box;
}