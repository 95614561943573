.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top : 17px;
    padding-bottom : 17px;
    padding-left : 24px;
    padding-right : 24px;
    width : 100%;
    z-index : 1000;
    box-sizing : border-box;
    top : 0;
    left : 0;
    position : fixed;
    background-color: white;
}

.footerButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index : 999;
    box-sizing : border-box;
    bottom : 0;
    left : 0;
    position : fixed;
    width : 100%;
    padding-top : 18.5px;
    padding-bottom : 18.5px;
    background-color: #9F6BFF;
    font-family: 'Pretendard';
    font-size: 5vw;       /* 16px에 해당하는 화면 너비 비율 */
    font-weight: 700;
    line-height: 6.5vw;   /* 20.8px에 해당하는 화면 너비 비율 */
    letter-spacing: -0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : white;
}

.logoIcon {
    width: 30vw;
    cursor: pointer;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}

.menuIcon {
    width: 8.44vw;
    height : 8.44vw;
    cursor: pointer;
}

.component {
    width : 100vw;
    display : flex;
    flex-direction: column;
    background-color: white;
    align-items: flex-start;
    justify-content: flex-start;
}

.component01 {
    width : 100vw;
    display : flex;
    flex-direction: column;
    background-color: #F9FAFF;
    align-items: flex-start;
    justify-content: flex-start;
}

.component02 {
    padding-top : 40px;
    padding-bottom : 48px;
    background: linear-gradient(to bottom, #FFFFFF 50%, #EDE2FF 50%);
}

.component03 {
    width : 100vw;
    display : flex;
    flex-direction: column;
    background-color: #F9F5FF;
    align-items: flex-start;
    justify-content: flex-start;
}

.component04 {
    width : 100vw;
    display : flex;
    flex-direction: column;
    background-color: #C7B4FF;
    align-items: flex-start;
    justify-content: flex-start;
}

.component05 {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right : 24px;
}

.component06Off {
    width : 100%;
    display : flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
    padding-top : 14px;
    padding-left : 24px;
    padding-right : 24px;
    padding-bottom : 14px;
}

.component06On {
    width : 100%;
    display : flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #FAFAFB;
    padding-top : 14px;
    padding-left : 24px;
    padding-right : 24px;
    padding-bottom : 20px;
}



.titleText {
    font-family: 'Pretendard';
    font-size: 6.87vw;
    line-height: 9.62vw;
    font-weight: 700;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0;
    margin: 0;
}

.semiTitleText {
    font-family: 'Pretendard';
    font-size: 5vw;
    line-height: 6vw;
    font-weight: 700;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9F6BFF;
    padding: 0;
    margin: 0;
}

.contentText {
    font-family: 'Pretendard';
    font-size: 4.37vw;
    line-height: 6.13vw;
    font-weight: 400;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0;
    margin: 0;
    color: #62686D;
}

.contentBoldText {
    font-family: 'Pretendard';
    font-size: 4.37vw;
    line-height: 6.13vw;
    font-weight: 700;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0;
    margin: 0;
}

.contentMainColorBoldText {
    font-family: 'Pretendard';
    font-size: 4.06vw;
    line-height: 5.2vw;
    font-weight: 700;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9F6BFF;
    padding: 0;
    margin: 0;
}

.componentTitleText {
    font-family: 'Pretendard';
    font-size: 7.19vw;
    line-height: 8.63vw;
    font-weight: 300;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0;
    margin: 0;
}

.downloadTitleText {
    font-family: "NanumSquareNeo";
    font-size: 5vw;
    line-height: 7vw;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1B1D1F;
}

.downloadTitleBoldText {
    font-family: "NanumSquareNeo";
    font-size: 6.25vw;
    line-height: 8.75vw;
    font-weight: 900;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1B1D1F;
}

.textContainer01 {
    display : flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left : 24px;
    padding-right : 24px;
    padding-top : 0px;
    padding-bottom : 0px;
}

.buttonContainer01 {
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: flex-start;
    padding-left : 24px;
    padding-right : 24px;
    width : 100%;
}

.selectButtonFalse {
    padding: 10px 12px 10px 12px;
    border-radius: 36px;
    border: 0.8px solid #E8E8E9;

    font-family: 'Pretendard';
    font-size: 4.06vw;      /* 13px에 해당하는 화면 너비 비율 */
    font-weight: 600;
    line-height: 4.87vw;    /* 15.6px에 해당하는 화면 너비 비율 */
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #454A50;
    background-color: white;
}

.selectButtonTrue {
    padding: 10px 12px 10px 12px;
    border-radius: 36px;
    border: 0.8px solid #9F6BFF;

    font-family: 'Pretendard';
    font-size: 4.06vw;      /* 13px에 해당하는 화면 너비 비율 */
    font-weight: 600;
    line-height: 4.87vw;    /* 15.6px에 해당하는 화면 너비 비율 */
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9F6BFF;
    background-color: white;
}

.button01 {       /* 내용에 맞는 너비 */
    border-radius: 27px;
    border: 1px solid #9F6BFF;
    background-color: white;
    width: 154px;
    height : 40px;
    padding-top : 11.5px;
    padding-bottom : 11.5px;
}

.buttonText01 {
    color: #9F6BFF;
    font-family: 'Pretendard';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.71px;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin : 0px;
    padding : 0px;
}

.downloadButton {
    width : 40.6250vw;
}

.componentImage01 {
    width : 100vw;
    align-items: center;
    padding-left: 77px;
    padding-right : 77px;
    box-sizing: border-box;
}

.componentImage02 {
    width : 100vw;
}

.componentImage03 {
    width: 73.1250vw;
    height : 79.8750vw;
    margin-right : 20vw;
}

.centerContainer {
    display: flex;
    justify-content: center;        /* 가로 중앙 정렬 */
    align-items: center;            /* 세로 중앙 정렬 (필요한 경우) */
    width: 100%;   
}

