.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide img {
    width : 100%;
    height : 100%;
    transition: all .3s ease;
}

.selectedItem {
    scale : 1;
    opacity: 1;
}

.unSelectedItem {
    scale : 0.85;
    transform-origin: center;
    opacity: 0.5;
}

.swiper-slide swiper-slide-active {
    margin-left: 0px;
}

.swiperSlide {
    width : 52.1063vw;
    height : 60.6897vh;
    padding : 0;
    margin : 0;
    box-sizing: border-box;
}

