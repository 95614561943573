html {
  font-size : 100%;
}

body {
  margin: 0;
  font-family: "Pretendard";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Pretendard',
    monospace;
}

@font-face {
  font-family: "Pretendard";
  font-style : normal;
  font-weight : 400;
  src : url("./assets/fonts/Pretendard-Regular.otf") format("opentype")
}

@font-face {
  font-family: "Pretendard";
  font-style : normal;
  font-weight : 500;
  src : url("./assets/fonts/Pretendard-Medium.otf") format("opentype")
}

@font-face {
  font-family: "Pretendard";
  font-style : normal;
  font-weight : 600;
  src : url("./assets/fonts/Pretendard-SemiBold.otf") format("opentype")
}

@font-face {
  font-family: "Pretendard";
  font-style : normal;
  font-weight : 700;
  src : url("./assets/fonts/Pretendard-Bold.otf") format("opentype")
}

@font-face {
  font-family: "NanumSquareNeo";
  font-style : normal;
  font-weight : 300;
  src : url("./assets/fonts/NanumSquareNeoOTF-Lt.otf") format("opentype")
}

@font-face {
  font-family: "NanumSquareNeo";
  font-style : normal;
  font-weight : 400;
  src : url("./assets/fonts/NanumSquareNeoOTF-Rg.otf") format("opentype")
}

@font-face {
  font-family: "NanumSquareNeo";
  font-style : normal;
  font-weight : 700;
  src : url("./assets/fonts/NanumSquareNeoOTF-Bd.otf") format("opentype")
}

@font-face {
  font-family: "NanumSquareNeo";
  font-style : normal;
  font-weight : 800;
  src : url("./assets/fonts/NanumSquareNeoOTF-Eb.otf") format("opentype")
}

@font-face {
  font-family: "NanumSquareNeo";
  font-style : normal;
  font-weight : 900;
  src : url("./assets/fonts/NanumSquareNeoOTF-Hv.otf") format("opentype")
}
