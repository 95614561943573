.screen {
    overflow-x: hidden;    
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE, Edge */
    scrollbar-width: none;/* Firefox */
}

.screen::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 엣지 */
}

.webHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
    top: 0;
    left: 0;
    position: fixed;
    background-color: white;
    padding-top : 24px;
    padding-bottom : 34px;

    @media screen and (max-width: 1440px) {
        padding-left: 6.1111vw;
        padding-right : 6.1111vw;
    }

    @media screen and (min-width: 1441px) {
        padding-left: 9.3750vw;
        padding-right : 9.3750vw;
    }
}

.webFooterButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 999;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    padding-top: 18.5px;
    padding-bottom: 18.5px;
    background-color: #9F6BFF;
    font-family: 'Pretendard';
    font-size: 5vw;
    font-weight: 700;
    line-height: 6.5vw;
    letter-spacing: -0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;
}

.webLogoIcon {
    width: 30vw;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.webMenuIcon {
    width: 8.44vw;
    height: 8.44vw;
    cursor: pointer;
}

.webComponent {
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;

    @media screen and (max-width: 1440px) {
        padding-left: 6.1111vw;
        padding-right : 0vw;
    }

    @media screen and (min-width: 1441px) {
        padding-left: 9.3750vw;
        padding-right : 0vw;
    }
}

.webComponent01 {
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: #F9FAFF;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    outline: none;
    border : none;
}

.webComponent02 {
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;

    @media screen and (max-width: 1440px) {
        padding-left: 6.1111vw;
        padding-right : 0vw;
    }

    @media screen and (min-width: 1441px) {
        padding-left: 9.3750vw;
        padding-right : 0vw;
    }
}

.webComponent03 {
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: #F9F5FF;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    outline: none;
  
    @media screen and (max-width: 1440px) {
        padding-left: 6.1111vw;
        padding-right : 0px;
        padding-top : 0px;
        padding-bottom : 0px;
    }

    @media screen and (min-width: 1441px) {
        padding-left: 9.3750vw;
        padding-top : 0px;
        padding-bottom : 0px;
        padding-right : 0px;
    }
}

.webComponent04 {
    padding-top : 40px;
    padding-bottom : 48px;
    background: linear-gradient(to bottom, #FFFFFF 50%, #EDE2FF 50%);
}

.webComponent05 {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
}

.webComponent06Off {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
    padding-top: 14px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 14px;
}

.webComponent06On {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #FAFAFB;
    padding-top: 14px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 20px;
}

.webComponent07 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;

    @media screen and (max-width: 1440px) {
        padding-left: 6.1111vw;
        padding-right : 6.1111vw;
        padding-top : 0px;
        padding-bottom : 0px;
    }

    @media screen and (min-width: 1441px) {
        padding-left: 9.3750vw;
        padding-right : 9.3750vw;
        padding-bottom : 0px;
        padding-right : 0px;
    }
}

.webComponent08 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #C7B4FF;
    box-sizing: border-box;

    @media screen and (max-width: 1440px) {
        padding-left: 6.1111vw;
        padding-right : 6.1111vw;
        padding-top : 35px;
        padding-bottom : 35px;
    }

    @media screen and (min-width: 1441px) {
        padding-left: 9.3750vw;
        padding-right : 9.3750vw;
        padding-bottom : 35px;
        padding-top : 35px;
    }
}

.webQnaDivider {
    width : 100%;
    height : 0.6px;
    background-color: #E8E8E9;
}

.webRowContainer01 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding : 0px;
    margin : 0px;
}

.webRowContainer02 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding : 1.8056vw 0px;
    margin : 0px;
}

.webRowContainer03 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin : 0px;
    box-sizing: border-box;

    @media screen and (max-width: 1440px) {
        padding-left: 0px;
        padding-right : 6.1111vw;
    }

    @media screen and (min-width: 1441px) {
        padding-left: 0px;
        padding-right : 9.3750vw;
    }
}

.webRowContainer04 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin : 0px;
}


.webColContainer01 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.webTextContainer01 {
    display : flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.webTextContainer02 {
    display : flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1440px) {
        padding-left: 6.1111vw;
        padding-top : 0px;
        padding-bottom : 0px;
    }

    @media screen and (min-width: 1441px) {
        padding-left: 9.3750vw;
        padding-top : 0px;
        padding-bottom : 0px;
    }
}

.webComponentCarousel04 {
    @media screen and (max-width: 1440px) {
        padding-left: 6.1111vw;
        padding-top : 0px;
        padding-bottom : 0px;
    }

    @media screen and (min-width: 1441px) {
        padding-left: 9.3750vw;
        padding-top : 0px;
        padding-bottom : 0px;
    }
}

.webQnaComponent {
    
    padding : 22px 18px;
    margin : 0px;
    border-radius: 16px;
    background-color: #FAFAFB;
    display : flex;
    box-sizing: border-box;
}

.webTitleText {
    font-family: 'Pretendard';
    font-size: 2.4vw;
    line-height: 3.6vw;
    font-weight: 700;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0;
    margin: 0;
}

.webAbsoulteTitleText {
    font-family: 'Pretendard';
    font-size: 2.4vw;
    line-height: 3.6vw;
    font-weight: 700;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0;
    margin: 0;

    position: absolute;
    z-index: 1000;
    top : 13.57vw;

    @media screen and (max-width: 1440px) {
        left : 6.1111vw;
        padding-top : 0px;
        padding-bottom : 0px;
    }

    @media screen and (min-width: 1441px) {
        left : 9.3750vw;
        padding-top : 0px;
        padding-bottom : 0px;
    }
}

.webSemiTitleText {
    font-family: 'Pretendard';
    font-size: 2vw;
    line-height: 2.5vw;
    font-weight: 700;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9F6BFF;
    padding: 0;
    margin: 0;
}

.webContentText {
    font-family: 'Pretendard';
    font-size: 1.75vw;
    line-height: 2.5vw;
    font-weight: 400;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0;
    margin: 0;
    color: #62686D;
}

.webContentBoldText {
    font-family: 'Pretendard';
    font-size: 1.75vw;
    line-height: 2.5vw;
    font-weight: 700;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0;
    margin: 0;
}

.webContentMainColorBoldText {
    font-family: 'Pretendard';
    font-size: 2.29vw;
    line-height: 2.75vw;
    font-weight: 700;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9F6BFF;
    padding: 0;
    margin: 0;
}

.webComponentTitleText {
    font-family: "Pretendard";
    font-size: 2.7778vw;
    font-weight: 700;
    line-height: 3.3333vw;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9F6BFF;
}

.webQnaTitleTextTrue {
    font-family: "Pretendard";
    font-size: 1.94vw; /* 1440px 기준 28px */
    font-weight: 600;
    line-height: 2.72vw; /* 1440px 기준 39.2px */
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #1B1D1F
}

.webQnaTitleTextFalse {
    font-family: "Pretendard";
    font-size: 1.94vw; /* 1440px 기준 28px */
    font-weight: 400;
    line-height: 2.72vw; /* 1440px 기준 39.2px */
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #454A50
}

.webQnaContentText {
    font-family: "Pretendard";
    font-size: 1.67vw; /* 1440px 기준 24px */
    font-weight: 400;
    line-height: 2.5vw; /* 1440px 기준 36px */
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #62686D
}

.webDownloadTitleText {
    font-family: "NanumSquareNeo";
    font-size: 2.22vw;
    line-height: 3.11vw;
    font-weight: 700;
    letter-spacing: -0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #1B1D1F;
}

.webDownloadSemiTitleText {
    font-family: "NanumSquareNeo";
    font-weight: 900;
    font-size: 2.78vw;
    line-height: 3.89vw;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #1B1D1F;
}

.webTitleButtonText {
    font-family: Pretendard;
    font-size: 1.6667vw;
    font-weight: 500;
    line-height: 2.0000vw;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : #454A50;
    padding : 1.3889vw 1.5278vw;
}

.webImage01 {
    width: 60.3472vw;
    height: 61.1111vw;
    margin : 0;
    padding : 0;
}

.webImage02 {
    width: 55.5556vw;
    height: 55.5556vw;
    box-sizing: border-box;
    margin : 0;
    padding : 0;
}

.webImage03 {
    width: 55.5556vw;
    height: 46.875vw;
    box-sizing: border-box;
    padding : 0px;
    margin : 0px;

    @media screen and (max-width: 1440px) {
        margin-right: 4.17vw;
    }

    @media screen and (min-width: 1441px) {
        margin-right: 5.21vw;
    }
}

.webImage04 {
    width: 54.2361vw;
    height: 61.1111vw;
    box-sizing: border-box;
    padding : 0px;
    margin : 0px;

    @media screen and (max-width: 1440px) {
        margin-right: 6.1111vw;
    }

    @media screen and (min-width: 1441px) {
        margin-right: 9.375vw;
    }
}

.webImage05 {
    width : 100vw;
    margin : 0px;
    padding : 0px;
    display: flex;
    box-sizing: border-box;
}

.webImage06 {
    width : 27.0833vw;
    height : 26.1111vw;
    margin : 0px;
    margin-right : 2.7778vw;
    padding : 0px;
    display : flex;
    box-sizing: border-box;
}

.webImage07 {
    width : 44.4444vw;
    height : 26.3889vw;
}

.webImage08 {
    width : 18.0556vw;
    height : 4.7222vw;
}

.webImage09 {
    width : 4.5833vw;
    height : 4.5833vw;
}

.webImage10 {
    width : 28.1250vw;
    height : 2.7778vw;
}

.webButtonContainer01 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
    gap: 1.3889vw;
}

.webButtonContainer02 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
    gap: 1.3889vw;

    @media screen and (max-width: 1440px) {
        padding-left: 6.1111vw;
    }

    @media screen and (min-width: 1441px) {
        padding-left: 9.3750vw;
    }
}

.webSelectButtonFalse {
    padding: 1.6667vw 2.7778vw;
    border-radius: 2.5vw;
    border: 0.0556vw solid #E8E8E9;
    font-family: 'Pretendard';
    font-size: 1.8056vw;
    line-height: 2.1667vw;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #454A50;
    background-color: white;
}

.webSelectButtonTrue {
    padding: 1.6667vw 2.7778vw;
    border-radius: 2.5vw;
    border: 0.0556vw solid #9F6BFF;
    font-family: 'Pretendard';
    font-size: 1.8056vw;
    line-height: 2.1667vw;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9F6BFF;
    background-color: white;
}

.headerButton {
    padding : 18.5px 72.5px ;
    border-radius: 80px;
    background-color: #9F6BFF;
}

.headerButtonText{
    font-family: "Pretendard";
    font-size: 1.8056vw;
    font-weight: 500;
    line-height: 2.1549vw;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color : white;
}

.webQuestionIcon {
    width : 2.7083vw;
    height : 2.7083vw;
    margin-right : 0.5556vw;
    padding : 0px;
}

