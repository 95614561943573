.ucMainCon {
    width: 100vw;
    height : 100vh;
    background-color: #FFF5F9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top : 5vh;
    padding-bottom : 5vh;
    box-sizing: border-box;
    overflow: hidden;
}

.mainConImage {
    width: 320px;
    height: 441px;
}

.buttonCon {
    flex-direction: column;
    align-items: center;
    justify-content: end;
}

.button01 {
    border-radius: 12px;
    width: calc(100% - 40px);
    height : 8.0000vh;
    background-color : #FC7B8B;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button01Txt {
    font-size: large;
    font-weight: 700;
    color : #FFFFFF;
}

.subText01 {
    font-size : small;
    color : #9fa5ad;
    font-weight: 400;
    margin-bottom: 100px;
}

.con01 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height : 100%
}

.con02 {
    width: 100%;
    height : 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}

.con03 {
    height : 100%;
    display: flex;
    justify-content: start;
    align-items: start;

}