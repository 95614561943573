

.footer2 {
    width : 100%;
    background-color: white;
    padding-left : 12.5%;
    padding-right : 12.5%;
    padding-top : 70px;
    padding-bottom : 70px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.footerBottomCon_01 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top : 0px;
}

.footerTextCon {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    margin-top: 0px;
}

.footerSemiTextCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top : 5px;
    margin-bottom: 5px;
}

.footerCompanyTitle {
    font-family: "Pretendard";
    font-size : 0.8333vw;
    font-weight: bold;
    margin-bottom : 20px;
    margin-top: 0px;
}

.footerSemiTitle {
    font-family: "Pretendard";
    font-size : 0.7292vw;
    font-weight: 500;
    color : #726E6E;
    margin-top: 0px;
    margin-bottom : 0px;
}

.footerTitleClc {
    color : #A4A5A5;
    font-family: "Pretendard";
    font-size : 0.7292vw;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom : 0px;
}

.footerSemiBoldTitle {
    font-family: "Pretendard";
    font-size : 0.7292vw;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom : 0px;
    color : #797A7C;
    text-decoration: none;
}

.footerLastCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.verticalDivider {
    width: 1px;
    height : 0.6vw;
    margin-left : 12px;
    margin-right : 12px;
    margin-top: 0px;
    background-color: #726E6E;
}

.bottomCon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.NaverBlogButton {
    margin-right : 20px;
}

.downloadCon {
    width : 100%;
    height : 32.2396vw;
    background-image : url("../../assets/images/download_background.png");
    background-size: 100% 100%;
    padding-left : 100px;
    padding-right : 100px;
    display: flex;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
}

.donwloadTextCon {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;    
}

.downloadTitle {
    font-family: "Pretendard";
    font-size : 2.0833vw;
    font-weight: 700;
    line-height: 140%;
    color : #FFFFFF;
    text-align: start;
}

.downloadBtnCon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 40px;
}

.AppleBtn {
    margin-right : 20px;
    width : 10.5208vw;
    height : auto;
}

.GoogleBtn {
    width : 10.5208vw;
    height : auto;
}

.carouselItem {
    display : flex;
    justify-content: center;
    align-items: center;
}

.carouselImg {
    width : 84%;
    height : auto;
}